exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-band-predictor-js": () => import("./../../../src/pages/band-predictor.js" /* webpackChunkName: "component---src-pages-band-predictor-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-end-user-license-agreement-js": () => import("./../../../src/pages/end-user-license-agreement.js" /* webpackChunkName: "component---src-pages-end-user-license-agreement-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-grievance-redressal-js": () => import("./../../../src/pages/grievance-redressal.js" /* webpackChunkName: "component---src-pages-grievance-redressal-js" */),
  "component---src-pages-ielts-mentors-js": () => import("./../../../src/pages/ielts-mentors.js" /* webpackChunkName: "component---src-pages-ielts-mentors-js" */),
  "component---src-pages-ielts-mock-test-alltests-js": () => import("./../../../src/pages/ielts-mock-test/alltests.js" /* webpackChunkName: "component---src-pages-ielts-mock-test-alltests-js" */),
  "component---src-pages-ielts-mock-test-js": () => import("./../../../src/pages/ielts-mock-test.js" /* webpackChunkName: "component---src-pages-ielts-mock-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oet-mock-test-js": () => import("./../../../src/pages/oet-mock-test.js" /* webpackChunkName: "component---src-pages-oet-mock-test-js" */),
  "component---src-pages-oet-score-predictor-js": () => import("./../../../src/pages/oet-score-predictor.js" /* webpackChunkName: "component---src-pages-oet-score-predictor-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-pte-mock-test-js": () => import("./../../../src/pages/pte-mock-test.js" /* webpackChunkName: "component---src-pages-pte-mock-test-js" */),
  "component---src-pages-pte-score-predictor-js": () => import("./../../../src/pages/pte-score-predictor.js" /* webpackChunkName: "component---src-pages-pte-score-predictor-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-schedule-consultation-js": () => import("./../../../src/pages/schedule-consultation.js" /* webpackChunkName: "component---src-pages-schedule-consultation-js" */),
  "component---src-pages-single-videos-js": () => import("./../../../src/pages/single-videos.js" /* webpackChunkName: "component---src-pages-single-videos-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-test-band-predictor-js": () => import("./../../../src/pages/test-band-predictor.js" /* webpackChunkName: "component---src-pages-test-band-predictor-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

